import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"
// import registerServiceWorker from "./registerServiceWorker"
import * as Sentry from "@sentry/react"

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
const SENTRY_APPLICATION_KEY = import.meta.env.VITE_SENTRY_APPLICATION_KEY

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: [SENTRY_APPLICATION_KEY],
        // Defines how to handle errors that contain third party stack frames.
        behaviour: "drop-error-if-contains-third-party-frames",
      }),
    ],
    environment: "production",
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/cultureandpeople\.eu\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
      // Random plugins/extensions
      "top.GLOBALS",
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
  })
}

const root = createRoot(document.getElementById("root")!)

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// registerServiceWorker()
