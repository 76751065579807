import Box from "@mui/material/Box"
import React, { FC } from "react"
import { Datagrid, TextField, useRecordContext } from "react-admin"
import { Radar } from "react-chartjs-2"

const LABELS = ["AnswerA", "AnswerB", "AnswerC", "AnswerD"]

type AverageScores = {
  answerA: number
  answerB: number
  answerC: number
  answerD: number
}
const convertAvgScoresToArr = ({ answerA, answerB, answerC, answerD }: AverageScores) => [
  answerA,
  answerB,
  answerC,
  answerD,
]

const mainColorRGB = "0,214,148"
const secondaryColorRGB = "19,44,76"

const getDataSets = (current: number[], preferred: number[]) => {
  return [
    {
      label: "Current",
      data: current,
      fill: true,
      backgroundColor: `rgba(${"40,72,115"}, 0.12)`,
      borderColor: `rgba(${secondaryColorRGB})`,
      pointBackgroundColor: `rgba(${secondaryColorRGB})`,
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: `rgba(${secondaryColorRGB})`,
    },
    {
      label: "Preferred",
      data: preferred,
      fill: true,
      backgroundColor: `rgba(${mainColorRGB}, 0.3)`,
      borderColor: `rgba(${mainColorRGB})`,
      pointBackgroundColor: `rgba(${mainColorRGB})`,
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: `rgba(${mainColorRGB})`,
    },
  ]
}

export const OcaiResults: FC = () => {
  return (
    <Datagrid bulkActionButtons={false}>
      <TextField source="name" />
      <RadarChart />
    </Datagrid>
  )
}

const RadarChart: FC = () => {
  const record = useRecordContext()
  const question = record.questions[0]

  const currentArr = convertAvgScoresToArr(question.currentScores)
  const preferredArr = convertAvgScoresToArr(question.preferredScores)

  const dataSets = getDataSets(currentArr, preferredArr)

  return (
    <Box style={{ display: "block", height: "400px", width: "400px" }}>
      <Radar
        data={{
          labels: LABELS,
          datasets: dataSets,
        }}
      />
    </Box>
  )
}
